export const strings = {
  addNewCategory: 'Add new category',
  createCategory: 'Create new category',
  enterCategoryNameHere: 'Category name',
  pleaseAssignAColour: 'Please assign a colour to this new category:',
  editCategory: 'Edit category',
  editCategoryColour: 'Edit category colour:',
  deleteCategory: 'Delete category',
  didYouKnowMessage:
    'Dragging a keyword into a category on the left will create a simple rule ' +
    'allocating all rows containing this keyword to the selected category.  Increasingly complex ' +
    'rules can be created using Keyword combinations and Custom Rules respectively.  The more ' +
    'complex a rule is, the greater the priority that will be attached to it in situations where ' +
    'transactions are matched by more than one rule. However, should you want a particular rule to always ' +
    "take precedence, regardless of its complexity, you can drag it into the appropriate 'High " +
    "Priority' area. ",
  referenceListsDidYouKnow:
    'You can categorise line items by reference lists. These are a pre-collated list of keywords categorised by a chosen topic. ' +
    'To upload a new reference list you can go to the import section, upload a new list and it will appear in this section.',
  referenceListExists: 'The following reference list(s) already exist:',
  referenceListsReplaceQuestion: 'Would you like to replace them all or cancel the upload?',
  lastKeywordsPageMessage:
    'Showing the first 20 pages, please search or filter to reveal more keywords.',
  highestValue: 'Highest value first',
  lowestValue: 'Lowest value first',
  alphabeticallyAtoZ: 'Alphabetically A-Z',
  alphabeticallyZtoA: 'Alphabetically Z-A',
  sortBy: 'Sort by value',
  keywordsCategorised: 'All keywords have been categorised',
  lineItemsCategorised: 'All line items have been categorised',
  referenceListsCategorised: 'All reference lists have been categorised',
  categorisationComplete: 'Nice one!',
  deleteConfirmation: 'Are you sure you want to delete this category?',
  deleteCategoryWarningMessage: lineItemCount => `This category still has some rules using it and ${lineItemCount} categorised line items. Proceeding will deleted the rule and uncategorise any line items in the category. Please be aware before clicking Delete Category.`,
  showKeywordsByTag: 'Show keywords by tag',
  allTags: 'All tags',
  anyTag: 'Any Tag',
  searchKeywords: 'Search',
  searchPlaceholder: 'Start typing to search',
  noKeywordsMatchSearch: 'No keywords match your search. Please try again.',
  noRefListsFound: 'There are no reference lists for this period.',
  applyApportionment: 'Apply apportionment',
  splitKeywordsToCategories1: 'Split the keyword into different categories?',
  splitKeywordsToCategories2:
    'Please note your apportionments must total 100% before you can apply them.',
  splitKeywordsToCategories3: remainingPercentage =>
    `Current apportionment is ${100 -
    remainingPercentage}%. Please allocate the remaining ${remainingPercentage}%.`,
  referenceLists: 'Reference lists',
  addNewReferenceList: 'Add a new list(s)',
  noRefListEntries: 'There are no entries listed within this reference list.',
  keyword: 'Keywords',
  keywordCombination: 'Keyword combinations',
  customRules: 'Custom rules',
  lineItems: 'Line items',
  totalLineItems: totalLineItemCount => `${totalLineItemCount} Line Items`,
  conflictedLineItems: 'Conflicted line items',
  flaggedLineItems: 'Flagged items',
  customKeywordsDidYouKnow:
    "Create a custom rule by dragging tags, operators and join words into the correct spaces. You can type keywords into the keyword space and add rows to the rule by selecting the 'Add new line' button. Once created, click on the 'drag' icon and move the rule into the appropriate category.",
  createCustomRule: 'Create a custom rule',
  editCustomRule: 'Edit a custom rule',
  customRulePlaceholder:
    'Example Custom Rule:\n\n' +
    '[Description] EQUALS ("laptop") AND\n' +
    '[Supplier] CONTAINS ("Laptops\'R\'Us" OR "Acme Laptops") AND\n' +
    '[Amount] >= (500) AND\n' +
    '[Amount] <= (1500)',
  customRuleNotePlaceholder: 'Add a note to describe the logic behind this custom rule',
  customRuleNoteAddDisclosurePlaceholder: 'Add a rule note additional disclosure',
  pendingValidation: 'Validating...',
  ruleValid: 'Rule syntax valid',
  operators: 'Operators',
  tags: 'Tags',
  comboKeywordsNoneFound: 'There are no other keywords to use in combination with this one',
  deleteRuleConfirmation:
    'Are you sure you want to delete this rule? Deleting this rule cannot be undone and could cause potential conflicts.',
  deleteRule: 'Delete rule',
  editRuleNoteTitle: 'New / Edit note',
  editRuleNoteAddDisclosureTitle: 'New / Edit rule note disclosure',
  editRuleNoteButton: 'Save note',
  editRuleNoteAddDisclosureButton: 'Save note',
  assignRegularPriority: 'Assign Regular Priority',
  assignHighPriority: 'Assign High Priority',
  assignToCategory: 'Assign to category',
  splitCategories: 'Split between different categories',
  apportionable: 'Apportionable',
  apportionableColor: '#000000',
  dragTag: 'Drag a tag',
  dragOperator: 'Drag an operator',
  dragJoin: 'Drag a join word',
  dragKeyword: 'Type a keyword(s) OR Drag keyword(s)',
  typeKeyword: 'Type a keyword(s)',
  categorisedSoFar: 'Categorised so far:',
  referenceListUploadSuccess: 'Reference list successfully uploaded.',
  referenceListUploadFailedTitle: 'Reference List Upload Failed',
  referenceListUploadFailedBody: 'Sorry, we weren\'t able to upload the reference list. Please try again.',
  referenceListTitle: 'Reference list: ',
  referenceListDescription: 'Please select a tag that you would like this reference list to be categorised on.',
  referenceListSelectTag: 'Select a tag to categorise reference list',
  referenceListProcessing: 'Processing uploaded reference list(s)...',
  referenceListDeleteText: (name, isEntry) => `Are you sure you want to delete the reference list ${isEntry ? 'entry' : ''} '${name}'?`,
  referenceListDeleteModalTitle: (name, isEntry) => `Delete reference list ${isEntry ? 'entry' : ''} : ${name}`,
  referenceListUnableDeleteTitle: (name, isEntry) => `${name} reference list  ${isEntry ? 'entry' : ''} cannot be deleted`,
  referenceListUnableDeleteText: 'This reference list cannot be deleted because it is being used by the following rules: ',
  referenceListUnableDeleteText2: 'You will need to delete the rules before you can delete this reference list.',
  referenceListRuleConflicts: (index, rule) => `Rule ${index}: ${rule}`,
  ruleCategorisationString: (ruleText, categories) => `${ruleText} to ${categories.join(', ')}`,
  amountCategorisedOutOf: (totalCategorised, amountValue) => `${totalCategorised} out of ${amountValue}`,
  byMonetaryAmount: 'By Monetary Amount',
  byLineItems: 'By Line Items',
  addNote: 'Add note (optional)',
  currentlyCategorising: tag => `You are currently categorising keywords by [${tag}]`,
  singleKeywordRuleDescription: (tag, keyword) => `[${tag}] CONTAINS [${keyword}]`,
  singleKeywordMultipleSelection: keywordSelections => `${keywordSelections} keywords selected`,
  comboKeywordRuleDescription: (firstTag, firstKeyword, secondTag, secondKeyword) => `[${firstTag}] CONTAINS [${firstKeyword}] AND [${secondTag}] CONTAINS [${secondKeyword}]`,
  lineItemsSearchDidYouKnowMessage: 'You searched for an item. Dragging the drag all search results into a category will include all returned search results.',
  allSearchResultsDragAllTooltip: 'Dragging this to any category will categorise all line items based on search term excluding line items present in contra shelf.',
  allSearchResultsDragAllText: 'Drag All Search Results',
  dragRuleFilterTooltip: 'Dragging this to any category will create a rule based on the filter above.',
  dragRuleFilterText: 'Drag Rule Filter',
  createRuleTooltip: 'Dragging this to any category will create a rule based on the selection from Create Rule Overlay.',
  createRuleText: 'Drag Rule',
  lineItemsCategorisationReasonModalTitle: categoryName => `Assign to ${categoryName}`,
  lineItemsCategorisationReasonModalDescription: 'Please add a note to describe why the line item was added to this category. i.e. example for categorising an item',
  lineItemsCategorisationReasonPlaceholder: 'Categorisation Reason',
  lineItemsCategorisationReasonValidationMessage: 'Categorisation Reason is Required.',
  ruleEditedSuccessfullyMessage: 'Rule edited successfully and is being recategorised.',
  noSearchResultsMessage: 'No results match your search. Please try again.',
  noItemsToDisplayMessage: 'There are no items to display.',
  categoryErrorMessage: errorMessage => `${errorMessage} Please try again.`,
  categoryErrorTitle: 'Error adding category',
  markLineItemsAsContra: 'Mark line Items as a contra',
  markLineItemsAsFlagged: oneLineItem => `Flag line item${oneLineItem ? '' : '(s)'}`,
  markLineItemsAsUnflagged: oneLineItem => `Unflag line item${oneLineItem ? '' : '(s)'}`,
  removeFromContraShelfText: 'Remove from ContraShelf',
  moveToContraShelfText: 'Move to ContraShelf',
  contrasAmountText: 'Total: ',
  flaggedItem: 'Flagged item',
  flaggedIconTooltip: 'Flagged items will appear in your categorisation screen for review.',
  summary: 'Summary',
  back: 'Back',
  apportionmentDefinitionNotCompleted: 'Apportionable items requires your attention to complete the analysis, Please check the apportionable category for more details.',
};
